import React from "react"
import { graphql } from "gatsby"
import Meta from "../../components/layouts/meta"
import BreadCrumb from "../../components/layouts/bread-crumb"
import Layout from "../../components/layouts/default"
import * as styles from "../../styles/page/second.module.scss"

// お知らせ
import TopInformation from "../../components/parts/top/information"

const ArticlePage = ({ data }) => {
  const title = 'お知らせ一覧'
  const informationData = data.information.edges

  return (
    <Layout>
      <Meta 
        title={title}
        path="/information/"
      />

      <BreadCrumb
        currentTitle={title}
      />

      <div className={`${styles.pageTitle} pb-4`}>
        <h1 className={styles.pageTitleHeading}>{title}</h1>
      </div>

      <div className={styles.pageWrapper}>
        { 
          informationData.map( (item, id) => {
            return <TopInformation key={`top-information-${id}`} index={id} data={item.node} />
          })
        }
      </div>

    </Layout>
  )
}
export default ArticlePage

export const query = graphql`
  query PageQuery {
    information: allMicrocmsInformation {
      edges {
        node {
          title
          link {
            blank
            fieldId
            href
          }
          publishedAt
        }
      }
    }
  }
`